import { Box, Link, Typography } from '@mui/material';
import { ModalContainer } from 'components/modals/modal-container/modal-container';
import { ApiContext } from 'components/providers/api-provider/api-provider';
import { FontSizes } from 'constants/font-sizes';
import { useGetResponseDataWithPagination } from 'hooks/use-get-response-data-with-pagination';
import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FiltersTableName } from 'store/modules/filters/filters.reducer';
import {
  closeModalByNameAction,
  PlainModals,
} from 'store/modules/modals/modals.reducer';
import { useIsPlainModalOpenSelector } from 'store/modules/modals/modals.selector';
import { setSelectedTable } from 'store/modules/selected-column/selected-column.reducer';
import { useSelectedColumnSelector } from 'store/modules/selected-column/selected-column.selector';
import {
  useActiveSortedFieldSelector,
  useActiveSortedOrderSelector,
} from 'store/modules/teams/teams.selector';
import { getSeverityIcon } from '../composite-risk-chart/composite-risk-chart';
import { TableComponent } from 'components/table/table-component/table-component';
import { TEAMS_MAIN_TABLE } from 'constants/test-ids';
import { tableStructureWithOrg } from 'pages/teams/constants';
import { TeamsRenderObj } from 'pages/teams/cell-renderer';
import { Colors } from 'constants/colors';
import { paths } from 'constants/routes';

export const TeamRiskModal = () => {
  const dispatch = useDispatch();
  const apiContext = useContext(ApiContext);
  const activeSortedOrder = useActiveSortedOrderSelector();
  const activeSortedField = useActiveSortedFieldSelector();
  const { applicableFilters: tableFilters } = useSelectedColumnSelector();

  const isTeamRiskModalOpen = useIsPlainModalOpenSelector(
    PlainModals.TEAM_RISK_MODAL,
  );

  const useLazyGetTeamsList = apiContext.baseApi?.useLazyGetTeamsListQuery;

  const { dataList, isLoading, isError, isFetching, isNoFindings } =
    useGetResponseDataWithPagination({
      baseApiCallback: useLazyGetTeamsList,
      sortBy: `${activeSortedOrder}${activeSortedField}`,
      tableFilters,
    });

  useEffect(() => {
    dispatch(setSelectedTable(FiltersTableName.TEAMS_LIST));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseModal = () => {
    dispatch(closeModalByNameAction(PlainModals.TEAM_RISK_MODAL));
  };

  return (
    <ModalContainer
      isOpenModal={isTeamRiskModalOpen}
      handleCloseModal={handleCloseModal}
      title="Resources Overview"
      modalWidth="fit-content"
      left="15%"
      modalHeight="500px"
    >
      <Box width={'920px'} marginTop={'10px'}>
        <Box display={'flex'} gap={'10px'} alignItems={'center'}>
          <Typography fontSize={FontSizes.fontSize16} fontWeight={500}>
            Teams Risk Summary
          </Typography>
          {getSeverityIcon(tableFilters?.highest_severity || 'all')}
        </Box>
        <TableComponent
          isListLoading={isLoading}
          isListErrored={isError}
          isEmptyList={isNoFindings}
          isListFetching={isFetching}
          list={dataList}
          dataTestId={TEAMS_MAIN_TABLE}
          tableColumns={tableStructureWithOrg}
          extraCellsRenderObj={TeamsRenderObj}
          tableHeight="300px"
          notPaginated
        />
        <Link
          color={Colors.binge}
          href={paths.teams}
          sx={{ textDecoration: 'none' }}
        >
          Click here to see all teams.
        </Link>
      </Box>
    </ModalContainer>
  );
};
