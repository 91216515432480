import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { SeverityChip } from 'pages/policies/components/severity-chip/severity-chip';
import { CellLabel } from 'components/table/styles';
import { ExtraCellsObject } from 'components/table/table-component/table-component';
import { AssetsRepositoriesItem } from 'store/apis/types';
import { paths } from 'constants/routes';
import { TableCellWithLink } from 'components/table/table-components/table-components';
import { VisibilityIcon } from '../../components/visibility/visibility-icon';
import { ASSETS_TABLE_NAME_LINK } from 'constants/test-ids';
import { useDispatch } from 'react-redux';
import {
  closeModalByNameAction,
  openModalByNameAction,
  PlainModals,
} from 'store/modules/modals/modals.reducer';
import {
  setActiveFindingsTab,
  setActiveTab,
} from 'store/modules/assets/assets.reducer';
import { setApplicableFilters } from 'store/modules/selected-column/selected-column.reducer';

const renderOwnerCell = (item: AssetsRepositoriesItem) => {
  if (!item.owners) return;

  return <CellLabel>{item.owners}</CellLabel>;
};

const renderCiCdCell = (item: AssetsRepositoriesItem) => {
  return (
    <Box sx={{ marginLeft: '15px' }}>{item.is_production ? 'Yes' : '-'}</Box>
  );
};

const RenderNameCell = (item: AssetsRepositoriesItem) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const repoSlug = item.org ? `${item.org}:${item.repo_slug}` : item.repo_slug;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }} pl={1}>
      <TableCellWithLink
        handleClick={() => {
          dispatch(setActiveTab(0));
          dispatch(setActiveFindingsTab(0));
          dispatch(closeModalByNameAction(PlainModals.ASSET_RISK_MODAL));
          navigate(
            `${paths.assetsDetails}?id=${repoSlug}&source=${item.source}`,
          );
        }}
        isActive={true}
        align="left"
        itemTitle={item.name}
        dataTestId={ASSETS_TABLE_NAME_LINK}
      />
      <Box
        sx={{
          display: 'flex',
          marginLeft: '10px',
          alignItems: 'center',
        }}
      >
        <VisibilityIcon
          watched={item.watched}
          hidden={item.hidden}
        ></VisibilityIcon>
      </Box>
    </Box>
  );
};

const renderRiskRatingCell = (item: AssetsRepositoriesItem) => {
  return item.highest_severity ? (
    <SeverityChip severityScore={parseInt(item.highest_severity)} />
  ) : (
    '-'
  );
};

const renderVisibilityCell = (item: AssetsRepositoriesItem) => {
  return (
    <Box sx={{ textTransform: 'capitalize', marginLeft: '15px' }}>
      {item.visibility}
    </Box>
  );
};

export const RenderVulnerabilityCell = (item: AssetsRepositoriesItem) => {
  const dispatch = useDispatch();
  if (!item?.vulnerability_counts) return <>-</>;
  const keys = ['oss', 'secret', 'sast', 'iac'];
  const sum = keys?.reduce((acc: number, key: string) => {
    if (item.vulnerability_counts[key])
      return acc + item.vulnerability_counts[key];
    return acc;
  }, 0);
  const handleClick = () => {
    dispatch(
      openModalByNameAction({
        type: PlainModals.ASSET_VULNERABILITY_COUNTS_MODAL,
        metaData: {
          vulnerabilityCounts: item?.vulnerability_counts,
          repo: item?.repo_slug,
          owner: item?.org,
          source: item?.source,
        },
      }),
    );
  };
  return sum > 0 ? (
    <TableCellWithLink
      dataTestId="vulnerability_counts_cell"
      handleClick={handleClick}
      itemTitle={sum}
      isActive
    />
  ) : (
    <>-</>
  );
};

const RenderOssCount = (item: AssetsRepositoriesItem) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(setApplicableFilters({ severity: ['CRITICAL', 'HIGH'] }));
    dispatch(setActiveTab(2));
    navigate(
      `${paths.assetsDetails}?id=${item.owner}:${item.name}&source=${item.source}`,
    );
  };
  return item?.vulnerability_counts?.oss ? (
    <TableCellWithLink
      itemTitle={
        item?.vulnerability_counts?.oss ? item.vulnerability_counts.oss : 0
      }
      handleClick={handleClick}
      dataTestId="vulnerability_counts_cell"
      isActive
    />
  ) : (
    0
  );
};

const RenderSecretsCount = (item: AssetsRepositoriesItem) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(setActiveTab(5));
    dispatch(setActiveFindingsTab(0));

    navigate(
      `${paths.assetsDetails}?id=${item.owner}:${item.name}&source=${item.source}`,
    );
  };
  return item?.vulnerability_counts?.secret ? (
    <TableCellWithLink
      itemTitle={
        item?.vulnerability_counts?.secret
          ? item.vulnerability_counts.secret
          : 0
      }
      handleClick={handleClick}
      dataTestId="vulnerability_counts_cell"
      isActive
    />
  ) : (
    0
  );
};

const RenderIacCount = (item: AssetsRepositoriesItem) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(setActiveTab(5));
    dispatch(setActiveFindingsTab(2));
    navigate(
      `${paths.assetsDetails}?id=${item.owner}:${item.name}&source=${item.source}`,
    );
  };
  return item?.vulnerability_counts?.iac ? (
    <TableCellWithLink
      itemTitle={
        item?.vulnerability_counts?.iac ? item.vulnerability_counts.iac : 0
      }
      handleClick={handleClick}
      dataTestId="vulnerability_counts_cell"
      isActive
    />
  ) : (
    0
  );
};

const RenderSastCount = (item: AssetsRepositoriesItem) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(setActiveTab(5));
    dispatch(setActiveFindingsTab(1));

    navigate(
      `${paths.assetsDetails}?id=${item.owner}:${item.name}&source=${item.source}`,
    );
  };
  return item?.vulnerability_counts?.sast ? (
    <TableCellWithLink
      itemTitle={
        item?.vulnerability_counts?.sast ? item.vulnerability_counts.sast : 0
      }
      handleClick={handleClick}
      dataTestId="vulnerability_counts_cell"
      isActive
    />
  ) : (
    0
  );
};

export const AssetsRenderObj: ExtraCellsObject = {
  highest_severity: renderRiskRatingCell,
  name: RenderNameCell,
  owners: renderOwnerCell,
  is_production: renderCiCdCell,
  visibility: renderVisibilityCell,
  vulnerabilities: RenderVulnerabilityCell,
  oss_count: RenderOssCount,
  secret_count: RenderSecretsCount,
  iac_count: RenderIacCount,
  sast_count: RenderSastCount,
};
