import { useContext, useEffect, useState } from 'react';
// import CircleIcon from '@mui/icons-material/Circle';
import { Box, SelectChangeEvent, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { PlotMarker } from 'plotly.js';
import Plot from 'react-plotly.js';
import { useNavigate } from 'react-router-dom';
import { Colors } from 'constants/colors';
import { ApiContext } from 'components/providers/api-provider/api-provider';
import { config } from './config';
import { StyledButton } from 'components/button/button';
import { paths } from 'constants/routes';
import { RiskChartCustomSlider } from './composite-risk-chart-slider';
import { SeverityFilter } from 'pages/alerts/components/severity-filter/severity-filter';
import { ErrorHandler } from 'components/error-handler/error-handler';
import { RISK_CHART_SEVERITY_FILTER } from 'constants/test-ids';
import { FontSizes } from 'constants/font-sizes';
import { SeverityIcon } from '../severity-icon/severity-icon';
import { ReactComponent as BubbleChartIcon } from 'assets/icons/bubble-chart.svg';
import { ReactComponent as TableIcon } from 'assets/icons/table.svg';

const colorGradientPallette = [
  {
    value: 0,
    color: Colors.info,
  },
  {
    value: 2,
    color: Colors.low,
  },
  {
    value: 3,
    color: Colors.medium,
  },
  {
    value: 4,
    color: Colors.high,
  },
  {
    value: 5,
    color: Colors.critical,
  },
];

const severityArray = ['None', 'None', 'Low', 'Moderate', 'High', 'Critical'];

const getSeverityText = (severityNumberString: string) =>
  severityArray[parseInt(severityNumberString)];

const getRadius = (total: number) => {
  if (total > 0 && total <= 100) {
    return 9;
  } else if (total > 100 && total <= 300) {
    return 7;
  } else {
    return 4;
  }
};

const colorCompare = (val: number) => {
  let finalColor = '#42D12F';
  colorGradientPallette.forEach((colorObject) => {
    if (val === colorObject.value) finalColor = colorObject.color;
  });
  return finalColor;
};

export const getSeverityIcon = (severity_filter: any) => {
  if (severity_filter === 'all') return;
  const severity = Number(severity_filter);
  let chipColor = colorGradientPallette.find(
    (sev) => sev.value === severity,
  )?.color;
  if (severity === 0)
    return (
      <Box
        display="flex"
        gap="5px"
        padding="5px"
        height="28px"
        alignItems={'center'}
        sx={{
          border: `1px solid ${chipColor}`,
          borderRadius: '3px',
        }}
      >
        <SeverityIcon iconSize="16px" severityScore={severity} />
        <Typography
          color={Colors.whiteTransparent07}
          fontSize={FontSizes.fontSize12}
        >
          {severityArray[severity]}
        </Typography>
      </Box>
    );
  return (
    <Box
      display="flex"
      gap="5px"
      padding="5px"
      height="28px"
      alignItems={'center'}
      sx={{
        border: `1px solid ${chipColor}4D`,
        background: `${chipColor}33`,
        borderRadius: '3px',
      }}
    >
      <SeverityIcon iconSize="16px" severityScore={severity} />
      <Typography
        color={Colors.whiteTransparent07}
        fontSize={FontSizes.fontSize12}
      >
        {severityArray[severity]}
      </Typography>
    </Box>
  );
};

export const CompositeRiskChart = ({
  severity,
  handleCloseModal,
  setIsChart,
}: any) => {
  const navigate = useNavigate();
  const ApiProvider = useContext(ApiContext);

  const [isLoad, setiSLoad] = useState(false);
  const [overprivilegeScore, setOverprivilegeScore] = useState(0);
  const [tableFilters, setTableFilters] = useState({
    severity_filter: 'all',
    min_overprivilege_score: 0,
  });
  const [applyScores, setApplyScores] = useState(0);
  const [filteredRiskData, setFilteredRiskData] = useState<
    {
      x: number[];
      y: number[];
      text: string[];
      linktext: string[];
      customdata: string[][];
      mode: 'markers';
      marker: Partial<PlotMarker>;
      type: 'scatter';
      hoverlabel: { bgcolor: string };
      hovertemplate: string;
    }[]
  >();

  const {
    data: dataList,
    isError,
    error,
  } = ApiProvider.baseApi?.useGetHealthOverviewChartQuery({
    tableFilters: severity === 'all' ? {} : { highest_severity: severity },
  });

  const [getFilteredDataList] =
    ApiProvider.baseApi?.useLazyGetHealthOverviewChartQuery();

  useEffect(() => {
    setTableFilters({
      severity_filter: severity,
      min_overprivilege_score: 0,
    });
  }, [severity]);

  const memoizedData: {
    x: number[];
    y: number[];
    text: string[];
    linktext: string[];
    customdata: string[][];
    mode: 'markers';
    marker: Partial<PlotMarker>;
    type: 'scatter';
    hoverlabel: { bgcolor: string };
    hovertemplate: string;
  }[] = [
    {
      x: dataList?.length
        ? dataList.map((user: any) => Number(user?.overprivilege_score || 0))
        : [],
      y: dataList?.length
        ? dataList.map(
            (user: any) => (Number(user?.highest_severity || 1) - 1) * 20,
          )
        : [],
      text: dataList?.length
        ? dataList.map((user: any) =>
            user?.display_name ? user.display_name : user.user_id,
          )
        : [],
      linktext: dataList?.length
        ? dataList.map((user: any) => user.user_id)
        : [],
      customdata: dataList?.length
        ? dataList.map((user: any) => {
            return [
              getSeverityText(user?.highest_severity || '2'),
              user?.user_id,
            ];
          })
        : [],
      mode: 'markers',
      hoverlabel: { bgcolor: Colors.blackPearl },
      hovertemplate:
        '<b>%{text} &#8212;&#8250;</b><br><br>' +
        'Risk: <b>%{customdata[0]}</b><br>' +
        'Overprivilege: <b>%{x:,.0f}</b><br>' +
        '<extra></extra>',
      marker: {
        size: dataList?.length
          ? dataList.map((user) => getRadius(dataList?.length))
          : [],
        showscale: false,
        color: dataList?.length
          ? dataList.map((user: any) =>
              colorCompare(Number(user?.highest_severity || 2)),
            )
          : [],
      },
      type: 'scatter',
    },
  ];

  const getFilteredRiskData = async () => {
    if (overprivilegeScore || tableFilters?.severity_filter) {
      setiSLoad(true);
      const constantTableFilters = {
        min_overprivilege_score: overprivilegeScore.toString(),
        only_incognito: false,
      };
      const data = await getFilteredDataList({
        tableFilters:
          tableFilters?.severity_filter === 'all'
            ? { ...constantTableFilters }
            : {
                ...constantTableFilters,
                highest_severity: tableFilters?.severity_filter,
              },
      });
      if (data?.data)
        setFilteredRiskData([
          {
            ...memoizedData[0],
            x: data?.data.map((user: any) =>
              Number(user?.overprivilege_score || 0),
            ),
            y: data?.data.map(
              (user: any) => (Number(user?.highest_severity || 1) - 1) * 20,
            ),
            text: data?.data?.length
              ? data?.data.map((user: any) =>
                  user?.display_name ? user.display_name : user.user_id,
                )
              : [],
            linktext: data?.data.length
              ? data?.data.map((user: any) => user?.user_id)
              : [],
            customdata: data?.data?.length
              ? data?.data.map((user: any) => {
                  return [
                    getSeverityText(user?.highest_severity || '1'),
                    user?.user_id,
                  ];
                })
              : [],
            hovertemplate:
              '<b>%{text} &#8212;&#8250;</b><br><br>' +
              'Risk: <b>%{customdata[0]}</b><br>' +
              'Overprivilege: <b>%{x:,.0f}</b><br>' +
              '<extra></extra>',
            marker: {
              ...memoizedData[0]?.marker,
              size: getRadius(data?.data?.length),
              color: data?.data?.length
                ? data?.data.map((user: any) =>
                    colorCompare(Number(user?.highest_severity || 2)),
                  )
                : [],
            },
          },
        ]);
      setApplyScores(applyScores + 1);
      setiSLoad(false);
    } else setApplyScores(0);
  };

  const navigateToIdentity = (data: any) => {
    navigate(`${paths.identityDetails}?user=${data.customdata[1]}`);
  };

  const onSeverityFilterChanged = (e: SelectChangeEvent) => {
    setTableFilters((prevValues) => {
      return {
        ...prevValues,
        severity_filter: e.target.value,
      };
    });
  };

  return (
    <Box width={'920px'}>
      <Box
        display="flex"
        flexDirection="row"
        width="100%"
        alignItems={'end'}
        justifyContent={'space-between'}
      >
        <Box
          display={'flex'}
          paddingBottom={'10px'}
          gap={'10px'}
          alignItems={'center'}
        >
          <Typography fontSize={FontSizes.fontSize16} fontWeight={500}>
            Identity Risk Summary
          </Typography>
          {getSeverityIcon(tableFilters.severity_filter)}
        </Box>
        <Box display="flex" width="65%" ml={1} alignItems="end">
          <Box sx={{ padding: '0px 0px 0px 15px' }} width="32%">
            <Typography
              sx={{ opacity: 0.5, textTransform: 'uppercase', fontSize: 11 }}
            >
              Overprivilege
            </Typography>
            <Box
              component="div"
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
              }}
            >
              <Box component="span" width="80%">
                <RiskChartCustomSlider
                  sx={{ width: '100%' }}
                  size="small"
                  value={overprivilegeScore}
                  onChange={(e, value) =>
                    setOverprivilegeScore(typeof value === 'number' ? value : 0)
                  }
                  valueLabelDisplay="auto"
                />
              </Box>
              <Box
                component="span"
                width="20%"
                sx={{ textAlign: 'center', paddingTop: '5px' }}
              >
                &ge; {overprivilegeScore}
              </Box>
            </Box>
          </Box>
          <Box paddingBottom={'10px'}>
            <SeverityFilter
              value={tableFilters.severity_filter}
              onChange={onSeverityFilterChanged}
              showInformational={true}
              dataTestId={RISK_CHART_SEVERITY_FILTER}
            />
          </Box>
          <Box paddingBottom={'10px'}>
            <StyledButton
              onClick={() => getFilteredRiskData()}
              variant="outlined"
              size="medium"
              color={applyScores ? 'primary' : 'info'}
              sx={{ ml: '10px', minWidth: '120px' }}
            >
              Apply
            </StyledButton>
          </Box>
          <Box
            sx={{
              background: Colors.purple,
              borderRadius: '5px',
              padding: '8px',
              height: '32px',
              width: '32px',
              marginLeft: '10px',
              marginBottom: '10px',
              cursor: 'pointer',
            }}
          >
            <BubbleChartIcon />
          </Box>
          <Box
            sx={{
              background: Colors.lightTiber,
              borderRadius: '5px',
              padding: '8px',
              height: '32px',
              width: '32px',
              marginLeft: '10px',
              marginBottom: '10px',
              cursor: 'pointer',
            }}
            onClick={() => setIsChart(false)}
          >
            <TableIcon />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          height: '52vh',
          border: `1px solid ${Colors.plantation}`,
          borderRadius: '4px',
        }}
      >
        <Box display="flex" flexDirection="row">
          <Box
            sx={{
              transform: 'rotate(180deg)',
              writingMode: 'vertical-lr',
              my: 'auto',
              ml: '5px',
            }}
          >
            Risk Profile
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              height: '43.5vh',
              alignItems: 'center',
              justifyContent: 'center',
              mt: 2,
              mr: 2,
            }}
          >
            {isError ? (
              <ErrorHandler error={error} />
            ) : isLoad ? (
              <CircularProgress size={30} sx={{ color: Colors.white }} />
            ) : dataList && dataList?.length < 1 ? (
              <Typography variant="h3" sx={{ color: Colors.white }}>
                No data
              </Typography>
            ) : (
              <>
                {applyScores &&
                filteredRiskData &&
                (overprivilegeScore || tableFilters?.severity_filter) ? (
                  <Plot
                    data={filteredRiskData}
                    layout={config.layout}
                    config={config.config}
                    style={config.style}
                    onClick={(data) => {
                      handleCloseModal();
                      navigateToIdentity(data.points[0]);
                    }}
                  />
                ) : (
                  <Plot
                    data={memoizedData!}
                    layout={config.layout}
                    config={config.config}
                    style={config.style}
                    onClick={(data) => {
                      handleCloseModal();
                      navigateToIdentity(data?.points[0]);
                    }}
                  />
                )}
              </>
            )}
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" marginTop={'10px'}>
          OverPrivilege Score
        </Box>
      </Box>
    </Box>
  );
};
