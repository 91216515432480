import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { Colors } from 'constants/colors';

export const RiskChartCustomSlider = styled(Slider)({
  color: Colors.purple,
  '& .MuiSlider-rail': {
    backgroundColor: Colors.darkCyan,
  },
  '& .MuiSlider-thumb': {
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
      height: 16,
      width: 16,
      border: '2px solid #FFFFFF',
    },
    '&:before': {
      display: 'none',
    },
  },
});
