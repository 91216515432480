import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { ExtraCellsObject } from 'components/table/table-component/table-component';
import { TeamsItemRes } from 'store/apis/types';
import { TableCellWithLink } from 'components/table/table-components/table-components';
import {
  closeModalByNameAction,
  openModalByNameAction,
  PlainModals,
} from 'store/modules/modals/modals.reducer';
import { paths } from 'constants/routes';
import { VisibilityIcon } from '../../components/visibility/visibility-icon';
import { FontSizes } from 'constants/font-sizes';
import { fontSecondary } from 'constants/font-family';
import { Colors } from 'constants/colors';
import {
  TEAMS_MEMBERS_MODAL_LINK,
  TEAMS_TABLE_NAME_LINK,
} from 'constants/test-ids';

const RenderMembersCell = (item: TeamsItemRes, type: 'total' | 'inactive') => {
  const dispatch = useDispatch();
  const isActive = type === 'total' ? !!item.num_total : !!item.num_inactive;
  const handleClick = () => {
    dispatch(
      openModalByNameAction({
        type: PlainModals.TEAMS_MEMBERS_MODAL,
        metaData: {
          members: type === 'total' ? item.members : item.inactive_members,
        },
      }),
    );
  };
  return (
    <TableCellWithLink
      handleClick={handleClick}
      isActive={isActive}
      itemTitle={type === 'total' ? item.num_total : item.num_inactive}
      dataTestId={TEAMS_MEMBERS_MODAL_LINK}
    />
  );
};

const RenderRepoNameCell = (item: TeamsItemRes) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(closeModalByNameAction(PlainModals.TEAM_RISK_MODAL));
    navigate(
      `${paths.teamsDetails}?team=${item.org}:${item.team}&source=${item.scm_source}`,
    );
  };
  return (
    <Typography
      onClick={handleClick}
      sx={{
        fontSize: FontSizes.fontSize14,
        fontFamily: fontSecondary,
        color: Colors.binge,
        textDecoration: 'none',
        cursor: 'pointer',
        textAlign: 'left',
      }}
      data-testid={TEAMS_TABLE_NAME_LINK}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        {item.name}
        <VisibilityIcon
          watched={item.watched}
          hidden={item.hidden}
        ></VisibilityIcon>
      </Box>
    </Typography>
  );
};

export const TeamsRenderObj: ExtraCellsObject = {
  num_inactive: (item: TeamsItemRes) => RenderMembersCell(item, 'inactive'),
  num_total: (item: TeamsItemRes) => RenderMembersCell(item, 'total'),
  name: RenderRepoNameCell,
  team_vulnerability_score: (item: TeamsItemRes) =>
    item?.team_vulnerability_score
      ? item?.team_vulnerability_score?.toFixed(2)
      : '-',
};
