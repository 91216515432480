import { Box, Link, Typography } from '@mui/material';
import { ModalContainer } from 'components/modals/modal-container/modal-container';
import { ApiContext } from 'components/providers/api-provider/api-provider';
import { FontSizes } from 'constants/font-sizes';
import { useGetResponseDataWithPagination } from 'hooks/use-get-response-data-with-pagination';
import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FiltersTableName } from 'store/modules/filters/filters.reducer';
import {
  closeModalByNameAction,
  PlainModals,
} from 'store/modules/modals/modals.reducer';
import { useIsPlainModalOpenSelector } from 'store/modules/modals/modals.selector';
import { setSelectedTable } from 'store/modules/selected-column/selected-column.reducer';
import { useSelectedColumnSelector } from 'store/modules/selected-column/selected-column.selector';
import { getSeverityIcon } from '../composite-risk-chart/composite-risk-chart';
import { AssetsRenderObj } from 'pages/assets/cell-renderer';
import {
  tableStructureWithOrg,
  tableStructureWithoutOrg,
} from 'pages/assets/constants';
import { ASSETS_MAIN_TABLE } from 'constants/test-ids';
import { TableComponent } from 'components/table/table-component/table-component';
import { Colors } from 'constants/colors';
import { paths } from 'constants/routes';
import {
  useActiveSortedFieldSelector,
  useActiveSortedOrderSelector,
} from 'store/modules/assets/assets.selector';

export const AssetRiskModal = () => {
  const dispatch = useDispatch();
  const apiContext = useContext(ApiContext);
  const activeSortedOrder = useActiveSortedOrderSelector();
  const activeSortedField = useActiveSortedFieldSelector();
  const { applicableFilters: tableFilters } = useSelectedColumnSelector();

  const isAssetRiskModalOpen = useIsPlainModalOpenSelector(
    PlainModals.ASSET_RISK_MODAL,
  );

  useEffect(() => {
    dispatch(setSelectedTable(FiltersTableName.ASSETS_REPOS_LIST));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const useLazyGetRepositoriesList =
    apiContext.baseApi?.useLazyGetRepositoriesListQuery;
  const {
    dataList: repositoriesList,
    isLoading: isRepositoriesListLoading,
    isError: isRepositoriesListErrored,
    isFetching: isRepositoriesListFetching,
    isNoFindings: isEmptyRepositoriesList,
  } = useGetResponseDataWithPagination({
    baseApiCallback: useLazyGetRepositoriesList,
    tableFilters,
    sortBy: `${activeSortedOrder}${activeSortedField}`,
  });

  const handleCloseModal = () => {
    dispatch(closeModalByNameAction(PlainModals.ASSET_RISK_MODAL));
  };

  return (
    <ModalContainer
      isOpenModal={isAssetRiskModalOpen}
      handleCloseModal={handleCloseModal}
      title="Resources Overview"
      modalWidth="fit-content"
      left="15%"
      modalHeight="500px"
    >
      <Box width={'920px'} marginTop={'10px'}>
        <Box display={'flex'} gap={'10px'} alignItems={'center'}>
          <Typography fontSize={FontSizes.fontSize16} fontWeight={500}>
            Assets Risk Summary
          </Typography>
          {getSeverityIcon(tableFilters?.highest_severity || 'all')}
        </Box>
        <TableComponent
          isListLoading={isRepositoriesListLoading}
          isListErrored={isRepositoriesListErrored}
          isEmptyList={isEmptyRepositoriesList}
          isListFetching={isRepositoriesListFetching}
          dataTestId={ASSETS_MAIN_TABLE}
          list={repositoriesList}
          tableColumns={
            repositoriesList.some((repo) => repo.org)
              ? tableStructureWithOrg
              : tableStructureWithoutOrg
          }
          extraCellsRenderObj={AssetsRenderObj}
          tableHeight="300px"
          notPaginated
        />
        <Link
          color={Colors.binge}
          href={paths.assets}
          sx={{ textDecoration: 'none' }}
        >
          Click here to see all assets.
        </Link>
      </Box>
    </ModalContainer>
  );
};
