export enum Colors {
  transparent = 'transparent',
  black = '#000000',
  lightSilver = '#c3c3c3',
  white = '#fff',
  blackPearl = '#081B35',
  tiber = '#020A15',
  plantation = '#234549',
  darkDesaturatedCyan = '#365155',
  purple = '#5346F8',
  lightBlue = '#5054FF',
  blue = '#4788C8',
  green = '#00CA1E',
  darkCyan = '#597275',
  red = '#FF1B00',
  darkGray = '#979797',
  whiteTransparent04 = 'rgba(255, 255, 255, 0.4)',
  whiteTransparent05 = 'rgba(255, 255, 255, 0.5)',
  whiteTransparent07 = 'rgba(255, 255, 255, 0.7)',
  lightRed = '#f77a63',
  orange = '#ff9d2b',
  yellow = '#fdd60d',
  lightGreen = '#74F257',
  binge = '#00E6FF',
  bingeTransparent05 = '#00E6FF80',
  lightTiber = '#030F20',
  active = '#39C670',
  inactive = '#1C4C54',
  kikorangiBlue = '#2E32C4',
  denim = '#030F20',
  darkDenim = '#020A15',
  buttonText = '#FFFFFF',
  smallCardBg = '#030F1E',
  smallCardBorder = '#1D375B',
  selectionBoxBorder = '#95A1B2',
  tabSelected = '#00E6FF',
  cardBorder = '#081B35',
  cardBackground = 'rgba(4, 20, 41, 0.45)',
  sectionDropShadow = 'rgba(7, 30, 45, 32)',
  sectionBackground = '#030A15',
  connectorBorderInactive = '#14202C',
  connectorAvatarBackground = '#041122',
  connectorButtonBackgroundInactive = '#123058',
  connectorButtonBorderInactive = '#DFE0E2',
  connectorActive = '#2155F5',
  collapseBorder = '#031019',
  drawerBackground = '#041429',
  drawerHeader = '#0A203C',
  drawerSubSelection = '#102949',
  drawerSelection = '#2155F5',
  info = 'rgb(255, 255, 255, 0.7)',
  low = '#00D1FF',
  medium = '#FF8514',
  high = '#F77C77',
  critical = '#FF1B00',
  tagBG = '#07182E',
  selectBG = '#041119',
  borderActive = '#2155F5',
  hovered = '#2155F5',
  sectionBoxShadow = '#051428',
  arrowIcon = '#DFE0E2',
  subtext = '#DFE0E2',
  hoveredBackground = '#041429',
  hoveredBoxShadow = '#071E2D',
  dropdownDisabledBackground = '#030A15',
  innerCardBg = '#04142973',
  chartBorder = '#293E5B',
  actionBorder = '#4F6C94',
  tooltipBorder = '#1C314D',
  tooltipBox = '#061223',
}
