// import { Annotations } from 'plotly.js';

export const config = {
  layout: {
    autosize: true,
    paper_bgcolor: 'transparent',
    plot_bgcolor: 'transparent',
    margin: {
      l: 65,
      r: 0,
      b: 16,
      t: 0,
      pad: 0,
    },
    font: {
      family: 'Poppins',
      size: 12,
      color: '#ffffff',
    },
    xaxis: {
      gridcolor: '#0F3135',
      range: [-1.5, 101.3],
    },
    yaxis: {
      gridcolor: '#6f8385',
      dtick: 25,
      tickangle: 0,
      range: [-1.3, 103.3],
      tickvals: [0, 20, 40, 60, 80, 100],
      ticktext: ['None', 'Low', 'Med', 'High', 'Critical', ''],
      tickfont: {
        size: 13,
        yanchor: 'top',
      },
    },
    // annotations: [
    //   {
    //     x: 5,
    //     y: 12,
    //     xref: 'x',
    //     yref: 'y',
    //     text: 'Low',
    //     showarrow: false,
    //     font: {
    //       color: '#fdd60d',
    //     },
    //     opacity: 0.6,
    //   },
    //   {
    //     x: 5,
    //     y: 35,
    //     xref: 'x',
    //     yref: 'y',
    //     text: 'Moderate',
    //     showarrow: false,
    //     font: {
    //       color: '#ff9d2b',
    //     },
    //     opacity: 0.6,
    //   },
    //   {
    //     x: 5,
    //     y: 60,
    //     xref: 'x',
    //     yref: 'y',
    //     text: 'High',
    //     showarrow: false,
    //     font: {
    //       color: '#f77a63',
    //     },
    //     opacity: 0.6,
    //   },
    //   {
    //     x: 5,
    //     y: 85,
    //     xref: 'x',
    //     yref: 'y',
    //     text: 'Critical',
    //     showarrow: false,
    //     font: {
    //       color: '#F85455',
    //     },
    //     opacity: 0.6,
    //   },
    // ] as Annotations[],
  },
  style: { width: '100%', height: '100%' },
  config: {
    responsive: true,
    displayModeBar: false,
    scrollZoom: false,
  },
};
