import { isObject } from 'lodash';
import { Box, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Circle } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { Colors } from 'constants/colors';
import { SeverityChip } from 'pages/policies/components/severity-chip/severity-chip';
import { ExtraCellsObject } from 'components/table/table-component/table-component';
import { IdentitiesUserItemRes } from 'store/apis/types';
import { paths } from 'constants/routes';
import {
  closeModalByNameAction,
  openModalByNameAction,
  PlainModals,
} from 'store/modules/modals/modals.reducer';
import { TableCellWithLink } from 'components/table/table-components/table-components';
import { VisibilityIcon } from 'components/visibility/visibility-icon';
import { CustomSvgIcon } from 'components/svg-icon/svg-icon';
import { ReactComponent as OutlinedUser } from 'assets/icons/user_outline.svg';
import { ReactComponent as BotUser } from 'assets/icons/bot.svg';
import { StyledTooltip } from 'components/tooltip/tooltip';
import { FontSizes } from 'constants/font-sizes';
import {
  IDENTITIES_TABLE_NAME_LINK,
  IDENTITY_STATUS_MODAL_LINK,
} from 'constants/test-ids';

const getAccountStatusLabel = (item: IdentitiesUserItemRes) => {
  return item.status_active ? 'Active' : 'Inactive';
};

const RenderNameCell = (item: IdentitiesUserItemRes) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClick = () => {
    dispatch(closeModalByNameAction(PlainModals.IDENTITY_RISK_MODAL));
    navigate(
      `${paths.identityDetails}?user=${encodeURIComponent(item.login)}&source=${
        item.scm_source
      }`,
    );
  };
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }} pl={1}>
      <Box
        width="22px"
        height="22px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ backgroundColor: Colors.blackPearl, borderRadius: 6 }}
        mr={1}
      >
        <StyledTooltip
          title={
            <>
              <Typography
                textTransform={'capitalize'}
                fontSize={FontSizes.fontSize12}
              >
                Type: {item?.account_type}
              </Typography>
              <Typography
                textTransform={'capitalize'}
                fontSize={FontSizes.fontSize12}
              >
                Status:{' '}
                <span
                  style={{
                    color: item.status_active ? Colors.green : Colors.red,
                  }}
                >
                  {item?.status_active ? 'Active' : 'Inactive'}
                </span>
              </Typography>
            </>
          }
        >
          <Box mt="1.6px">
            <CustomSvgIcon>
              {item.account_type === 'user' ? <OutlinedUser /> : <BotUser />}
            </CustomSvgIcon>
            <Circle
              sx={{
                color: item.status_active ? Colors.green : Colors.plantation,
                width: '6px',
                height: '6px',
                position: 'absolute',
                top: '30px',
                left: '33px',
                border: `solid 0.1px ${Colors.blackPearl}`,
                borderRadius: 1,
              }}
            />
          </Box>
        </StyledTooltip>
      </Box>
      <TableCellWithLink
        handleClick={handleClick}
        isActive={true}
        itemTitle={item.display_name ? item.display_name : item.login}
        align="left"
        dataTestId={IDENTITIES_TABLE_NAME_LINK}
      />
      <Box
        sx={{
          display: 'flex',
          marginLeft: '10px',
          alignItems: 'center',
        }}
      >
        <VisibilityIcon
          watched={item.watched}
          hidden={item.hidden}
        ></VisibilityIcon>
      </Box>
    </Box>
  );
};

const renderRiskRatingCell = (item: IdentitiesUserItemRes) => {
  return item.highest_severity ? (
    <Box data-testid="identities-risk-rating-cell">
      <SeverityChip severityScore={parseInt(item.highest_severity)} />
    </Box>
  ) : (
    '-'
  );
};

const RenderPreviewLink = (item: {
  emails: string | Record<string, string>;
  identity_status: string;
  user_id: string;
}) => {
  const dispatch = useDispatch();
  const emails = isObject(item.emails) && Object.keys(item.emails);
  const openEmailsModal = () => {
    if (!emails) return;
    dispatch(
      openModalByNameAction({
        type: PlainModals.IDENTITIES_EMAILS_MODAL,
        metaData: { emails: emails },
      }),
    );
  };
  return (
    <TableCellWithLink
      handleClick={openEmailsModal}
      isActive={!!emails}
      itemTitle={item.identity_status}
      dataTestId={IDENTITY_STATUS_MODAL_LINK}
    />
  );
};

export const IdentityRendererWithLogin: ExtraCellsObject = {
  highest_severity: renderRiskRatingCell,
  status_active: getAccountStatusLabel,
  login: RenderNameCell,
  identity_status: RenderPreviewLink,
  composite_score: (item: IdentitiesUserItemRes) =>
    item?.composite_score ? Math.round(item?.composite_score) : 0,
};

export const IdentityRendererWithDisplayName: ExtraCellsObject = {
  highest_severity: renderRiskRatingCell,
  display_name: RenderNameCell,
  identity_status: RenderPreviewLink,
  composite_score: (item: IdentitiesUserItemRes) =>
    item?.composite_score ? Math.round(item?.composite_score) : 0,
};
