import React from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { StyledTooltip } from 'components/tooltip/tooltip';

interface Props {
  watched: boolean | null | undefined;
  hidden: boolean | null | undefined;
}

export const VisibilityIcon = ({ watched, hidden }: Props) => {
  return (
    <>
      {hidden && (
        <StyledTooltip title={'Hidden'}>
          <VisibilityOff sx={{ width: '16px', height: '16px' }} />
        </StyledTooltip>
      )}
      {watched && (
        <StyledTooltip title={'Watched'}>
          <Visibility sx={{ width: '16px', height: '16px' }} />
        </StyledTooltip>
      )}
    </>
  );
};
