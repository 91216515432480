import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  closeModalByNameAction,
  PlainModals,
} from 'store/modules/modals/modals.reducer';
import {
  useGetModalMetaData,
  useIsPlainModalOpenSelector,
} from 'store/modules/modals/modals.selector';
import { ModalContainer } from 'components/modals/modal-container/modal-container';
import {
  CompositeRiskChart,
  getSeverityIcon,
} from '../composite-risk-chart/composite-risk-chart';
import { useSelectedColumnSelector } from 'store/modules/selected-column/selected-column.selector';
import { ApiContext } from 'components/providers/api-provider/api-provider';
import { setSelectedTable } from 'store/modules/selected-column/selected-column.reducer';
import { TableNamesOfFilters } from 'components/table/table-filters-components/filter-renderer/filter-renderer';
import { useGetResponseDataWithPagination } from 'hooks/use-get-response-data-with-pagination';
import {
  useActiveSortedFieldSelector,
  useActiveSortedOrderSelector,
} from 'store/modules/identities/identities.selector';
import { SortOrder } from 'utils/sort-order/sort';
import { Box, Link, Typography } from '@mui/material';
import { FontSizes } from 'constants/font-sizes';
import { Colors } from 'constants/colors';
import { ReactComponent as BubbleChartIcon } from 'assets/icons/bubble-chart.svg';
import { ReactComponent as TableIcon } from 'assets/icons/table.svg';
import { IDENTITIES_MAIN_TABLE } from 'constants/test-ids';
import {
  tableStructureWithDisplayName,
  tableStructureWithLogin,
} from 'pages/identities/constants';
import {
  IdentityRendererWithDisplayName,
  IdentityRendererWithLogin,
} from 'pages/identities/cell-renderer';
import { TableComponent } from 'components/table/table-component/table-component';
import { paths } from 'constants/routes';

export const IdentityRiskModal = () => {
  const dispatch = useDispatch();
  const apiContext = useContext(ApiContext);
  const [isChart, setIsChart] = useState(true);
  const isIdentityRiskModalOpen = useIsPlainModalOpenSelector(
    PlainModals.IDENTITY_RISK_MODAL,
  );

  const { severity } = useGetModalMetaData();
  const activeSortedOrder = useActiveSortedOrderSelector();
  const activeSortedField = useActiveSortedFieldSelector();
  const { applicableFilters: tableFilters } = useSelectedColumnSelector();

  const reversedSortOrder =
    activeSortedOrder === SortOrder.Desc ? SortOrder.Asc : SortOrder.Desc;

  const useLazyGetIdentitiesUserListQuery =
    apiContext.baseApi?.useLazyGetIdentitiesUserListQuery;

  useEffect(() => {
    dispatch(setSelectedTable(TableNamesOfFilters.IDENTITIES_USERS_LIST));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    dataList: identitiesUserList,
    isLoading: isIdentitiesUserListLoading,
    isError: isIdentitiesUserListErrored,
    isFetching: isIdentitiesUserListFetching,
    isNoFindings: isEmptyIdentitiesUserList,
  } = useGetResponseDataWithPagination({
    baseApiCallback: useLazyGetIdentitiesUserListQuery,
    sortBy: `${activeSortedOrder}${
      activeSortedField === 'external'
        ? `${activeSortedField},${reversedSortOrder}internal,${reversedSortOrder}unknown`
        : activeSortedField
    }`,
    itemsPerPage: 10,
    tableFilters,
  });

  const handleCloseModal = () => {
    dispatch(closeModalByNameAction(PlainModals.IDENTITY_RISK_MODAL));
  };

  return (
    <ModalContainer
      isOpenModal={isIdentityRiskModalOpen}
      handleCloseModal={handleCloseModal}
      title="Resources Overview"
      modalWidth="fit-content"
      left="15%"
      modalHeight="500px"
    >
      {isChart ? (
        <CompositeRiskChart
          severity={severity || 'all'}
          handleCloseModal={handleCloseModal}
          setIsChart={setIsChart}
        />
      ) : (
        <Box width={'920px'} marginTop={'10px'}>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'end'}
          >
            <Box display={'flex'} gap={'10px'} alignItems={'center'}>
              <Typography fontSize={FontSizes.fontSize16} fontWeight={500}>
                Identity Risk Summary
              </Typography>
              {getSeverityIcon(severity || 'all')}
            </Box>
            <Box display={'flex'} gap={'5px'}>
              <Box
                sx={{
                  background: Colors.lightTiber,
                  borderRadius: '5px',
                  padding: '8px',
                  height: '32px',
                  width: '32px',
                  marginLeft: '10px',
                  marginBottom: '10px',
                  cursor: 'pointer',
                }}
                onClick={() => setIsChart(true)}
              >
                <BubbleChartIcon />
              </Box>
              <Box
                sx={{
                  background: Colors.purple,
                  borderRadius: '5px',
                  padding: '8px',
                  height: '32px',
                  width: '32px',
                  marginLeft: '10px',
                  marginBottom: '10px',
                  cursor: 'pointer',
                }}
              >
                <TableIcon />
              </Box>
            </Box>
          </Box>
          <TableComponent
            isListLoading={isIdentitiesUserListLoading}
            isListErrored={isIdentitiesUserListErrored}
            isEmptyList={isEmptyIdentitiesUserList}
            isListFetching={isIdentitiesUserListFetching}
            dataTestId={IDENTITIES_MAIN_TABLE}
            list={identitiesUserList}
            tableColumns={
              identitiesUserList.some((user) => user.display_name !== null)
                ? tableStructureWithDisplayName
                : tableStructureWithLogin
            }
            extraCellsRenderObj={
              identitiesUserList.some((user) => user.display_name !== null)
                ? IdentityRendererWithDisplayName
                : IdentityRendererWithLogin
            }
            tableHeight="300px"
            notPaginated
          />
          <Link
            color={Colors.binge}
            href={paths.identities}
            sx={{ textDecoration: 'none' }}
          >
            Click here to see all identities.
          </Link>
        </Box>
      )}
    </ModalContainer>
  );
};
