import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import {
  closeModalByNameAction,
  PlainModals,
} from 'store/modules/modals/modals.reducer';

import {
  StyledTableBodyCell,
  StyledTableHeaderCell,
} from 'components/table/styles';
import {
  useGetModalMetaData,
  useIsPlainModalOpenSelector,
} from 'store/modules/modals/modals.selector';
import { ModalContainer } from 'components/modals/modal-container/modal-container';
import { paths } from 'constants/routes';
import { Member } from 'store/apis/types';
import { FontSizes } from 'constants/font-sizes';
import { fontSecondary } from 'constants/font-family';
import { Colors } from 'constants/colors';
import { ReactComponent as ExternalIcon } from 'assets/icons/external.svg';

export const MembersModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const metaData = useGetModalMetaData();

  //@ts-ignore
  const members: Member[] = metaData?.members;

  const isOpenModal = useIsPlainModalOpenSelector(
    PlainModals.TEAMS_MEMBERS_MODAL,
  );

  const handleCloseModal = () => {
    dispatch(closeModalByNameAction(PlainModals.TEAMS_MEMBERS_MODAL));
  };

  return (
    <ModalContainer
      isOpenModal={isOpenModal}
      title="Group/Team"
      handleCloseModal={handleCloseModal}
      modalWidth="700px"
      modalHeight="400px"
    >
      <TableContainer
        sx={{
          marginTop: '15px',
          maxHeight: '750px',
          border: `1px solid #0C3C44`,
        }}
      >
        <Table
          stickyHeader
          sx={{ minWidth: '550px', backgroundColor: 'transparent' }}
          size="small"
        >
          <TableHead sx={{ height: '42px' }}>
            <TableRow>
              <StyledTableHeaderCell align="left">
                Team Name
              </StyledTableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {members?.map((item, index) => (
              <TableRow
                key={index}
                sx={{
                  '&.MuiTableRow-hover:hover': {
                    backgroundColor: Colors.blackPearl,
                  },
                  '&:nth-of-type(odd)': {
                    backgroundColor: Colors.lightTiber,
                  },
                  '&:nth-of-type(even)': {
                    backgroundColor: Colors.tiber,
                  },
                }}
              >
                <StyledTableBodyCell sx={{ borderBottom: '0px' }}>
                  <Typography
                    onClick={() => {
                      dispatch(
                        closeModalByNameAction(PlainModals.TEAM_RISK_MODAL),
                      );
                      navigate(`${paths.identityDetails}?user=${item.login}`);
                      handleCloseModal();
                    }}
                    sx={{
                      fontSize: FontSizes.fontSize14,
                      fontFamily: fontSecondary,
                      color: Colors.binge,
                      textDecoration: 'none',
                      cursor: 'pointer',
                      textAlign: 'left',
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {item.name || item.login}
                      <ExternalIcon style={{ marginLeft: '10px' }} />
                    </Box>
                  </Typography>
                </StyledTableBodyCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ModalContainer>
  );
};
